<template>
  <div class="behalf-feedback">
    <full-scroll></full-scroll>
    <div class="
                msg-title
                flex flex-justify-between flex-pack-center
                color_FF
            ">
      <div class="flex flex-pack-center flex-1">
        <img src="@/assets/national_emblem.png" alt="" class="msg-national m_r20" />
        <div class="text_align_last">
          <p class="font-size24 m_b10">广阳镇人大代表之家</p>
          <p class="font-size12">
            GUANG YANG ZHEN REN DA DAI BIAO ZHI JIA
          </p>
        </div>
      </div>
      <div class="msg-title-mid-box font-size32 font-blod flex-1">
        <img src="@/assets/images/tot-top-title.png" alt="" />
        <div class="msg-title-mid-box-title">代表接访平台</div>
      </div>

      <div class="flex flex-end flex-1">
        <div class="my_text_center m_r30" id="refreshContainer" @click="gotoRefresh">
          <img id="refreshImage" src="@/assets/refresh.png" class="msg-backhome1 ">
          <div class=" font-size16">刷新</div>
        </div>
        <div class="my_text_center" @click="backHome">
          <img src="@/assets/images/backhome-new.png" alt="" class="msg-backhome2" />
          <div class=" font-size16">返回首页</div>
        </div>
      </div>
    </div>

    <div class="msg-mid flex flex-v flex-justify-center flex-pack-center">

      <div class="feedback-box flex flex-justify-between">
        <div class="feedback-left">
          <p class="
                            feedback-left-tot-title
                            color_FF
                            my_text_center
                            m_b10
                            font-size28 font-blod
                        ">
            代表访问室
          </p>
          <div class="font-size24 feedback-left-tot-box">
            <div class=" color_FF padding30 font-blod" :class="Isitdisplayed == true ? 'actived' : ''"
              @click="gotoOnlinefollow" v-if="Whethertoload && whetherdistribution">
              在线接访
            </div>
            <van-collapse v-model="activeNames" accordion @change="changeACtiveName">
              <van-collapse-item :title="item.name" :name="item.id" v-for="(item, id) in leaderTypeList"
                :key="id">
                <ul class="">
                  <li v-for="(nitem, index) in leaderTypeList[id].child" :key="index" :class="isActived == index ? 'actived' : ''"
                    @click="goToLeaderMlist(nitem.id, index)">
                    <span></span>{{ nitem.name }}
                  </li>
                </ul>
              </van-collapse-item>
            </van-collapse>
          </div>
        </div>
        <div class="feedback-right flex flex-pack-center">
          <div class="feedback-right-box color_FF">
            <ul>
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <li class="
                                    feedback-right-list
                                    font-size24
                                    m_b10
                                    flex flex-pack-center
                                " v-for="(item, id) in list" :key="id">
                  <img :src="item.type == 1
                                        ? ccVideo
                                        : item.type == 2
                                            ? ccWord
                                            : item.type == 3
                                                ? ccAudio
                                                : ''
                                        " alt="" class="m_r20" />
                  <div class="m_r20 flex-1">
                    <div class="m_b10 flex">
                      <p class="m_r20 m_b20">
                        反馈人：{{ item.realName }}
                      </p>
                      <p>联系电话：{{ item.contactNum }}</p>
                    </div>
                    <div class="m_b10">
                      反馈时间：{{ item.createTime }}
                    </div>
                  </div>
                  <div v-if="item.status == 1" @click="goToPass(item)" class="gotochuli">
                    收集意见
                  </div>
                  <div v-if="item.status == 2" @click="goToInfo(item)" class="gotochuli">
                    查看详情
                  </div>
                </li>
              </van-list>

            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="
                msg-bottom
                flex flex-justify-between flex-pack-center
                font-size28
            ">
      <div class="my_text_center" @click="goBack">
        <img src="@/assets/images/return-new.png" alt="" class="msg-return m_b10" />
        <p class="font-size24 color_FF">返回上级</p>
      </div>
      <div class="flex">
        <div class="complaint-2 flex flex-justify-center flex-pack-center">
          代表接访平台
        </div>
        <div class="complaint-1 flex flex-justify-center flex-pack-center" @click="gotama">
          代表信息
        </div>
      </div>

      <div class="my_text_center color_FF">
        <p class="font-size24 m_b10 font-blod">{{ todayMtime }}</p>
        <p class="font-size14">{{ todayTime }}</p>
      </div>
    </div>
    <!--我要处理 start-->
    <div class="opinion-hide" v-if="isShowHide">
      <div class="opinion-hide-box">
        <div class="font-size28">
          <div class=" font-blod font-size30 my_text_center m_b20">
            办理详情
          </div>
          <div class="font-blod font-size28 m_b20">
            办理信息:
          </div>
          <div class="font-size24">
            <div class="m_b20 flex flex-justify-between">
              <div class="">反馈人：{{ passBehalfFeedback.realName }}</div>
              <div>联系电话：{{
                  passBehalfFeedback.contactNum }}
              </div>
            </div>
            <div class="m_b20">反馈时间：{{ passBehalfFeedback.createTime }}</div>
            <div class="m_b20">反馈内容：</div>
            <div class="m_b10">
              <video :src="passBehalfFeedback.file" controls v-if="passBehalfFeedback.type == 1"></video>
              <audio :src="passBehalfFeedback.file" controls v-if="passBehalfFeedback.type == 3"></audio>
              <div v-if="passBehalfFeedback.type == 2" class="text-list">
                {{ passBehalfFeedback.content }}
              </div>
            </div>
            <div class="mig-list">
            </div>
          </div>
          <div class="m_b20 font-blod">办理意见：</div>
          <div class="m_b20">
            <van-field v-model="result" maxlength="200" type="textarea" placeholder="请输入办理意见(200字)"
              class="hide-pass-input" />
          </div>
        </div>
        <div class="
                        flex flex-justify-around
                        my_text_center
                    ">
          <div @click="passHide" class="opinion-hide-sure-h m_r20">取消</div>
          <div @click="sureSub(passBehalfFeedback.id)" class="opinion-hide-sure">确定</div>
        </div>
      </div>
    </div>
    <!--我要处理 end-->

    <!--我要展示 start-->
    <div class="opinion-hide" v-if="isShowHidePass">
      <div class="opinion-hide-box">
        <div class="font-size28">
          <div class=" font-blod font-size30 my_text_center m_b20">
            反馈详情
          </div>
          <div class="font-blod font-size28 m_b20">
            反馈信息:
          </div>
          <div class="font-size24">
            <div class="m_b20 flex flex-justify-between">
              <div class="">反馈人：{{ passBehalfFeedback.realName }}</div>
              <div>联系电话：{{
                  passBehalfFeedback.contactNum }}
              </div>
            </div>
            <div class="m_b20">反馈时间：{{ passBehalfFeedback.createTime }}</div>
            <div class="m_b20">反馈内容：</div>
            <div class="m_b10">
              <video :src="passBehalfFeedback.file" controls v-if="passBehalfFeedback.type == 1"></video>
              <audio :src="passBehalfFeedback.file" controls v-if="passBehalfFeedback.type == 3"></audio>
              <div v-if="passBehalfFeedback.type == 2" class="text-list">
                {{ passBehalfFeedback.content }}
              </div>
            </div>
            <div class="mig-list">
            </div>
          </div>
          <div class="m_b20">处理意见：</div>
          <div class="m_b20 font-blod text-d">{{ passBehalfFeedback.result }}</div>
        </div>
        <div class="
                        opinion-hide-sure
                        flex flex-justify-around
                        my_text_center
                    " @click="passHide">
          关闭
        </div>
      </div>
    </div>
    <!--我要处理 end-->
  </div>
</template>
<script>
import { behlfDelegateList, handleFeedback } from "@/request/api.js";
import { dateTimeFormat } from "@/request/until.js";

import { Toast } from "vant";
import { Dialog } from "vant";
import fullScroll from "@/components/fullScroll.vue";

export default {
  data () {
    return {
      Whethertoload: false,//是否加载数据
      whetherdistribution: true,//是否显示在线接访
      isForNowDelegate: 1,//解放记录
      isForDeLegate: true,//是否是在线街坊
      loading: false,
      finished: false,
      activeNames: 0,
      todayMtime: "",
      todayTime: "",
      isActived: 0,
      Isitdisplayed: true, //在线接访的背景色
      list: [],
      showId: null,
      result: "", //处理意见
      isShowHide: false,
      isShowHidePass: false,
      passBehalfFeedback: null,
      opinion: null,
      ccVideo: require("@/assets/images/cc-video.png"),
      ccWord: require("@/assets/images/cc-word.png"),
      ccAudio: require("@/assets/images/cc-audio.png"),
      leaderTypeList: [
        {
          id: 2,
          name: "接访记录",
          child: [
            {
              id: 1,
              status: 0,
              name: "我的记录"
            },
            {
              id: 2,
              status: 1,
              name: "待处理"
            },
            {
              id: 3,
              status: 2,
              name: "已处理"
            }
          ]
        }
      ],
      pageNum: 1,
      pageSize: 6,
      status: 1
    };
  },
  components: {
    fullScroll,
    [Dialog.Component.name]: Dialog.Component
  },
  beforeCreate () {
    if (!sessionStorage.getItem("Authorization")) {
      Toast.fail("请先登录");
      this.$router.push("/");
    }
  },
  created () {
    this.getTime();
    this.Onlinefollowup();

  },

  mounted () {
    setInterval(() => {
      this.getTime();
    }, 1000);
  },

  methods: {
    /**
     * 刷新接口
     */
    gotoRefresh () {
      var image = document.getElementById("refreshImage");
      image.classList.add("rotate"); // 添加旋转动画类
      setTimeout(function() {
        image.classList.remove("rotate"); // 移除旋转动画类
      }, 1000); // 设置旋转动画时长，单位为毫秒
      this.stopInterval();
      setTimeout(() => {
        this.pageNum = 1;
        this.list = [];
        this.visitsbythecongressData();
        Toast.success("刷新成功");
        this.startInterval();
      }, 1000);
    },

    /**
     * 设置定时任务
     */
    startInterval () {
      this.intervalId = setInterval(() => {
        this.pageNum = 1;
        this.list = [];
        this.visitsbythecongressData();
      }, 1 * 60 * 1000 * 2);
    },

    /**
     *  清除定时任务
     */
    stopInterval () {
      clearInterval(this.intervalId);
    },

    /**
     * 在线接访
     */
    gotoOnlinefollow () {
      this.activeNames = 0;
      this.Isitdisplayed = true;
      this.isActived = 0;
      this.isForDeLegate - true;
      this.stopInterval();
      this.Onlinefollowup();
    },

    /**
     * 是否加载数据
     */
    Onlinefollowup () {
      Dialog.confirm({
        title: "在线接访室",
        message:
          "是否确定需要获取新的待接访数据信息"
      }).then(() => {
        this.Isitdisplayed = true;
        this.Whethertoload = true;
        this.whetherdistribution = true;
        this.activeNames = 0;
        this.isForDeLegate = true;
        this.status = 1;
        this.isActived = 1;
        this.pageNum = 1;
        this.list = [];
        this.visitsbythecongressData();
        this.startInterval();
      }).catch(() => {
        this.list = [];
        this.finished = true;
        this.Whethertoload = true;
        this.whetherdistribution = false;
        this.activeNames = 2;
        this.isForDeLegate = false;
        this.pageNum = 1;
        this.status = 0;
        this.getFetch();
      });
    },

    visitsbythecongressData () {
      // visitsbythecongress().then((res) => {
      //   if (res.code == 200) {
      //     this.getFetch();
      //   }
      // });
      this.getFetch();
    },

    goToLeaderMlist (id, index) {
      this.Isitdisplayed = false;
      this.pageNum = 1;
      this.status = id;
      this.isActived = index;
      this.list = [];
      this.status = index;
      this.getFetch();
    },

    changeACtiveName () {
      this.stopInterval();
      this.Isitdisplayed = false;
      this.pageNum = 1;
      this.isActived = 0;
      this.list = [];
      this.status = 0;
      this.isForDeLegate = false;
      this.getFetch();
    },
    getTime () {
      let _this = this;
      let time = "";
      time = dateTimeFormat(new Date());
      _this.todayTime = time.split(" ")[0];
      _this.todayMtime = time.split(" ")[1].slice(0, 5);
    },
    getFetch () {
      const requestData = {};
      if (this.isForDeLegate === true) {
        Object.assign(requestData, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          isForNowDelegate: 1
        });
      } else {
        Object.assign(requestData, {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          status: this.status,
          isNowDelegate: 1
        });
      }

      behlfDelegateList(requestData).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let data = res.data.list;
          if (data.length == 0) {
            this.finished = true;
          } else {
            if (this.pageNum == res.data.totalPage) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            data.forEach((item) => {
              item.createTime = item.createTime.split(" ")[0];
            });
            this.totalPage = res.data.totalPage;
            this.list.push(...data);
          }

        }
      });
    },

    /**
     * 加载数据
     */
    onLoad () {
      if (this.Whethertoload) {
        if (this.totalPage <= this.pageNum) {
          this.loading = false;
          this.finished = true;
        } else {
          this.loading = true;
          this.pageNum++;
          this.getFetch();
        }
      }
    },

    //点击立即处理
    goToPass (data) {
      if (this.showId != null) {
        Toast.fail("请先处理上一个反馈");
      } else {
        this.showId = data.id;
        this.passBehalfFeedback = data;
        this.isShowHide = true;
      }
    },

    cancel () {
      this.showId = null;
    },

    sureSub (id) {
      if (this.result == "") {
        Toast.fail("请输入处理意见");
      } else {
        handleFeedback({ id: id, result: this.result }).then((res) => {
          if (res.code == 200) {
            Toast.success(res.message);
            this.result = "";
            this.showId = null;
            this.isShowHide = false;
            this.passBehalfFeedback = null;
            this.list = [];
            this.getFetch();
          } else {
            Toast.fail(res.message);
          }
        });
      }
    },

    goToInfo (data) {
      this.isShowHidePass = true;
      this.passBehalfFeedback = data;
    },

    passHide () {
      this.showId = null;
      this.isShowHide = false;
      this.isShowHidePass = false;
    },

    //返回首页
    backHome () {
      this.stopInterval();
      this.$router.push("/");
    },
    goBack () {
      this.stopInterval();
      this.$router.back();
    },

    gotama () {
      this.stopInterval();
      this.$router.push("/leaderinfo");
    }
  },
  beforeDestroy () {
    this.stopInterval();
  }

  // beforeRouteLeave(to, from, next) {
  //     if (to.path === "/leaderinfo" ) {
  //         from.meta.keepAlive = true;
  //     } else {
  //         from.meta.keepAlive = false;
  //     }
  //     next();
  // },

};
</script>
